<template>
  <div class="p15 lh24px">
    <p>
      用户每天首次登陆，点击进入"任务中心"后，系统将分配每日任务。每完成一个任务，都可以获得指定或随机的现金奖励，任务难度越大获得的奖励越多。
    </p>
    <p class="h6">规则说明：</p>
    <p>1.任务刷新时间</p>
    <p>
      无论任务完成和领奖与否系统都会在每天00:00自动刷新，刷新后系统将重新随机分配任务给用户。
    </p>
    <p class="mt10">2.每日任务分为签到类任务和随机类任务两种，示例：</p>
    <div class="pl5">
      <p>【签到】</p>
      <p class="pl5">
        用户可每天登陆舒昕逸家平台完成签到获得随机现金奖励。
      </p>
      <p>【观看视频，点赞3次】</p>
      <p class="pl5">
        用户当日在触见板块观看技师视频，且点赞数>3次即可完成该任务。
      </p>
    </div>
    <p class="mt10">
      3.任何用户或团体以不正常的方式进行套取活动优惠，平台方保留在不通知的情况下冻结或关闭相关账户的权利，并不退还所得奖励，且用户会被列入黑名单。
    </p>
    <p class="mt10">
      4.如果您有任何问题或疑问，随时欢迎您联系我们在线客服400-962-3686协助处理。
    </p>
    <p class="mt10">5.为避免文字理解差异，舒昕逸家保留本活动最终解释权。</p>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.h6 {
  color: #333;
  font-size: 14px;
  margin: 6px 0;
  font-weight: bold;
}
</style>
